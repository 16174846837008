import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';

import './style.css';

function VirtualCard() {

    React.useEffect(() => {
        window.setTitle('Check In');
    }, []);


    const { member_uuid } = useParams();

    return (
        <div className="virtual-card">

            <div className="virtual-card__qr">
                <span className="virtual-card__qr__label">Scan This QR at check in</span>
                <QRCode className="virtual-card__qr__code" value={"https://sdssnsg.uk/card/" + member_uuid} bgColor="transparent" />
            </div>

        </div>
    );
}

export default VirtualCard;
