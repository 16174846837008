import { openDB } from "idb";

export async function addCard(db, card) {
    const tx = db.transaction("cards", "readwrite");
    await tx.store.add(card);
    await tx.done;
}

export async function getCard(db, uuid) {
    const tx = db.transaction("cards", "readonly");
    const card = await tx.store.get(uuid);
    await tx.done;
    return card;
}

export async function getAllCards(db) {
    const tx = db.transaction("cards", "readonly");
    const cards = await tx.store.getAll();
    await tx.done;
    return cards;
}

export async function deleteCard(db, uuid) {
    const tx = db.transaction("cards", "readwrite");
    await tx.store.delete(uuid);
    await tx.done;
}

export async function clearCards(db) {
    const tx = db.transaction("cards", "readwrite");
    await tx.store.clear();
    await tx.done;
}

async function initDB() {

    const db = await openDB("sdssnsg", 1, {
        upgrade(db, oldVersion, newVersion, transaction) {
            switch (oldVersion) {

                // migration like interface for upgrading the database fields
                case 0:
                    db.createObjectStore("cards", { keyPath: "uuid" });
                    break;

                default:
                    break;
            }
        }
    });

    // request persistent storage
    if (navigator.storage && navigator.storage.persist) {
        const result = await navigator.storage.persist();
        console.log("Persistent Storage: " + result);
        if (!result) {
            // alert("There was an error setting up the app for offline use. You will need to install the app to your homescreen to save your membership cards.");
        }
    }

    // extend the db object with the functions in this file
    db.addCard = addCard.bind(null, db);
    db.getCard = getCard.bind(null, db);
    db.getAllCards = getAllCards.bind(null, db);
    db.deleteCard = deleteCard.bind(null, db);
    db.clearCards = clearCards.bind(null, db);

    return db;
}


export default initDB;