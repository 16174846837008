import React from 'react';
import { NavLink } from 'react-router-dom';

// import './style.css';

let [cards, setCards] = [null, null];

class ListItem extends React.Component {
    render() {
        return (<li className="py-3" key={this?.props?.uuid}>
            <div className="flex items-center space-x-2">

                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium truncate text-white">
                        {this?.props?.name}
                    </p>
                    <p className="text-sm truncate text-gray-400">
                        Added: {new Date(this?.props?.added).toLocaleDateString()}
                        <br />
                        <small className="text-xs truncate text-gray-600">
                            {this?.props?.uuid}
                        </small>
                    </p>
                </div>

                <button type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={async () => {
                    await (await window.db).deleteCard(this?.props?.uuid);
                    // remove from cards
                    setCards(cards.filter((card) => card.uuid !== this?.props?.uuid));
                }}>
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-white" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx="4" cy="4" r="3" />
                    </svg>
                    <span>Delete</span>
                </button>

            </div>
        </li>)
    }
}


function MyCards() {

    [cards, setCards] = React.useState([]);


    React.useEffect(() => {

        window.setTitle("My Cards");

        (async () => {
            (await window.db).getAllCards().then((cards) => {
                console.log(cards);
                setCards(cards);
            });
        })();


        return () => {
            // cleanup
            setCards([]);
        }
    }, []);


    return (
        <>
            <div className="w-full py-1 px-4">
                <div className="flow-root">
                    <ul className="divide-y divide-gray-700">
                        {/* for each card of cards */}
                        {cards.map((card) => {
                            return (<ListItem name={card.name} added={card.added} uuid={card.uuid} />)
                        })}

                        {/* Add New */}
                        <li className="py-3" key="ADD_NEW_ITEM">
                            <NavLink to="/scan" className="flex items-center space-x-2">
                                <button type="button" className=" text-center w-full text-md  px-4 py-1.5 border border-transparent font-medium rounded-full shadow-sm text-white bg-green-600 text-center">
                                    <span>Add New</span>
                                </button>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    );
}

export default MyCards;