
import React from 'react';

const modalContainer = {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '99999',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const modalCenter = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '230px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}

function InstallPrompt() {

    const [beforeInstallPrompt, setBeforeInstallPrompt] = React.useState(null);
    const [isDismissed, setIsDismissed] = React.useState(false);

    const onBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setBeforeInstallPrompt(e);
    }

    React.useEffect(() => {
        window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
        }
    }, []);

    const onButtonPress = () => {
        if (!beforeInstallPrompt) return;

        window.addEventListener('appinstalled', () => {
            setIsDismissed(true);
        });

        beforeInstallPrompt.prompt();
        beforeInstallPrompt.userChoice.then((choiceResult) => {
            setBeforeInstallPrompt(null);
        });
    }

    return (
        <>
            {beforeInstallPrompt && !isDismissed && <>
                <div id="popup-modal" tabindex="-1" className="fixed top-0 left-0 right-0 z-50 p-4" style={modalContainer}>
                    <div className="relative w-full max-w-md max-h-full" style={modalCenter}>
                        <div className="relative rounded-lg shadow bg-gray-700">

                            <button onClick={() => setIsDismissed(true)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white" data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>

                            <div className="p-6 text-center">
                                <div className="mx-auto mb-4 text-center text-6xl">👋</div>
                                <h2 className="mb-2 text-xl font-bold text-white">
                                    Hey There!
                                </h2>
                                <h3 className="mb-5 text-lg font-normal  text-gray-400">
                                    We <strong>strongly</strong> recommend that you add The Membership app to your home screen for the best experience.
                                </h3>
                                <button onClick={onButtonPress} data-modal-hide="popup-modal" type="button" className="text-white bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                    Add To Homescreen
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </>}

            {isDismissed && beforeInstallPrompt && <>

                <div className="fixed bottom-16 left-0 z-50 w-full py-3 text-center">
                    {/* <b>Add to your homescreen</b><br /> */}
                    <button onClick={onButtonPress} data-modal-hide="popup-modal" type="button" className="text-white  bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                        Add to Homescreen Now
                    </button>
                </div>
            </>}

        </>
    )
}

export default InstallPrompt