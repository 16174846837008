

import React from 'react';

function TopBar() {

    const [title, setTitle] = React.useState(document.title);
    window.setTitle = setTitle;

    return (
        <>
            <nav className="border-gray-200 bg-gray-900">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">

                    <span className="flex items-center">
                        <img src="/logo.svg" className="h-8 mr-5 " alt="SDSSNSG" />
                        <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
                            {title}
                        </span>
                    </span>

                </div>
            </nav>
        </>
    )
}

export default TopBar