import React from 'react';

import QrReader from 'react-qr-scanner'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

import './style.css';

function CardScanner() {

    React.useEffect(() => {
        window.setTitle('Add New Card');
    }, []);

    const { member_uuid } = useParams();

    const uuidMatch = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/;

    const [scannedQR, setScannedQR] = React.useState(member_uuid || null);
    const [cardName, setCardName] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState(null);
    const navigate = useNavigate();

    const checkIfAlreadyAdded = async (scannedQR) => {
        const card = await (await window.db).getCard(scannedQR);
        if (card) {
            setScannedQR(null);
            setErrorMessage('This card has already been added, please scan another card.');
            return true;
        }
        return false;
    }

    const doBeep = (frequency = 650, duration = 0.5, type = "sine") => {
        // play a beep by modulating the volume of a sine wave
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const oscillator = audioCtx.createOscillator();
        const gainNode = audioCtx.createGain();
        oscillator.connect(gainNode);
        gainNode.connect(audioCtx.destination);
        oscillator.type = type;
        oscillator.frequency.value = frequency;
        oscillator.start();
        gainNode.gain.exponentialRampToValueAtTime(0.00001, audioCtx.currentTime + duration);
        // destroy the audio context after 1 second
        setTimeout(() => { audioCtx.close() }, (duration * 1000) + 200);
    }

    const onError = (err) => {
    }

    const onScan = async (data) => {
        if (!data) return;

        const { text } = data;

        const match = text.match(uuidMatch);
        if (match) {

            const alreadyAdded = await checkIfAlreadyAdded(match[0]);

            // bad beep and exit
            if (alreadyAdded) return doBeep(300, 1)

            // good beep
            doBeep(850, 1);

            // we have a valid member_uuid
            setScannedQR(match[0]);
            setErrorMessage(null);
        } else {
            // bad beep
            setErrorMessage('Invalid QR Code, please try again.');
            return doBeep(300, 1, 'square');
        }
    }

    const doCardSave = async () => {
        // save the card to the database
        await (await window.db).addCard({
            name: cardName,
            uuid: scannedQR,
            added: Date.now()
        });

        // redirect to my cards with react router
        navigate('/cards');
    }

    if (scannedQR) checkIfAlreadyAdded(scannedQR).then();


    return (
        <div className="w-full py-1 px-4">
            <div className="flow-root">

                {errorMessage && <div className="bg-red-600 text-white text-center py-2 px-4 mt-3 rounded-lg">
                    {errorMessage}
                </div>}

                {!scannedQR && <>

                    {/* title */}
                    <h1 className="text-2xl font-semibold whitespace-nowrap text-white text-center py-3">Scan Membership QR</h1>

                    <div className="card-scanner flex justify-center">

                        <div className="card-scanner__preview rounded-lg overflow-hidden bg-stone-400">
                            <span className="card-scanner__preview--enable-camera text-black">Please Enable Your Camera</span>
                            <QrReader
                                className="card-scanner__preview__video"
                                delay={300}
                                onError={onError}
                                onScan={onScan}
                                style={{ width: '100%' }}
                                constraints={{
                                    audio: false,
                                    video: { facingMode: "environment" }
                                }}
                            />
                        </div>
                    </div>
                </>}
                {scannedQR && <>
                    {/* get name for this card */}
                    <h1 className="text-2xl font-semibold whitespace-nowrap text-white text-center py-3">Add Card</h1>

                    {/* text input for name */}
                    <div className="flex justify-center">
                        <div className="w-full">
                            <div className="flex flex-col">
                                <label htmlFor="name" className="text-white text-sm font-semibold">Cardholders Full Name</label>
                                <input type="text" name="name" id="name" className="rounded-lg text-white px-3 py-2 bg-transparent border border-gray-500 focus:border-gray-300 focus:ring-1 focus:ring-gray-300 focus:outline-none" onInput={(e) => {
                                    setCardName(e.target.value);
                                }} />
                            </div>

                            {/* save button, only when name has text in */}
                            <div className="flex justify-center mt-4">
                                <button type="button" disabled={cardName.length < 5} className="px-2.5 py-1.5 border border-transparent text-xl font-medium rounded-full shadow-sm text-white bg-green-600 w-full text-center disabled:opacity-50 disabled:cursor-not-allowed" onClick={doCardSave}>
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </>}

            </div>
        </div>
    );
}

export default CardScanner;
