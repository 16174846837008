import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';

// import './style.css';


function CheckinPicker() {

    const navigate = useNavigate();

    React.useEffect(() => {
        window.setTitle('Check In');
    }, []);

    const [cards, setCards] = React.useState([]);
    const [checkedState, setCheckedState] = React.useState();
    const [checkedCardUUIDs, setCheckedCardUUIDs] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            (await window.db).getAllCards().then((cards) => {

                // if we only have one card, we can skip right to the checkin page
                if (cards.length === 1) return navigate(`/check-in/${cards[0].uuid}`);

                setCards(cards);
                setCheckedState(new Array(cards.length).fill(false));
            });
        })();

        return () => {
            // cleanup
            setCards([]);
        }
    }, []);

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        const updatedCheckedCardUUIDs = cards.map((card, index) => updatedCheckedState[index] ? card.uuid : null).filter((item) => item !== null);

        setCheckedCardUUIDs(updatedCheckedCardUUIDs);
        // console.log(updatedCheckedCardUUIDs);
    }



    return (
        <>
            <div className="w-full py-1 px-4">
                <div className="flow-root pt-3">

                    {cards.length === 0 &&
                        <div className="text-center w-full">
                            <span className="ml-3 block text-sm font-medium text-white">
                                You have no cards. Please add a card first.
                                <br />
                                <NavLink to="/scan">
                                    <button type="button" className=" text-center w-full text-md  px-4 py-1.5 border border-transparent font-medium rounded-full shadow-sm text-white bg-green-600 text-center">
                                        <span>Add New</span>
                                    </button>
                                </NavLink>
                            </span>
                        </div>
                    }
                    {cards.length !== 0 && <>
                        <span className="text-white text-md mb-2">Please select everyone in your party today.</span>

                        <ul className="divide-y divide-gray-700 ">
                            {/* for each card of cards */}
                            {cards.map((card, index) => {
                                return (<>
                                    {/* checkbox list of cards who are attending today */}
                                    <li className="py-3" key={card.uuid}>
                                        <div className="flex items-center space-x-2">
                                            <input id={card.uuid} name={card.uuid} checked={checkedState[index]} onChange={() => handleOnChange(index)} type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                            <label htmlFor={card.uuid} className="ml-3 block text-sm font-medium text-white">
                                                {card.name}
                                            </label>
                                        </div>

                                    </li>

                                </>)
                            })}

                            <li className="py-3" key="DO_VIRTUAL_CARD">
                                <NavLink to={"/check-in/" + checkedCardUUIDs.join(';')} disabled={checkedCardUUIDs.length < 1} className="flex items-center space-x-2">
                                    <button disabled={checkedCardUUIDs.length < 1} type="button" className=" text-center w-full text-md  px-4 py-1.5 border border-transparent font-medium rounded-full shadow-sm text-white bg-green-600 text-center disabled:opacity-50 disabled:cursor-not-allowed">
                                        <span>Show Check In QR</span>
                                    </button>
                                </NavLink>
                            </li>


                        </ul>
                    </>}
                </div>
            </div>
        </>
    );
}

export default CheckinPicker;