import React from 'react';

function Home() {

    React.useEffect(() => {
        window.setTitle('Home');
    }, []);

    return (
        <></>
    );
}

export default Home;
