import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';

import initDB from './database';

// pages import
import Home from './pages/Home';
import CardScanner from './pages/CardScanner';
import VirtualCard from './pages/VirtualCard';
import MyCards from './pages/MyCards';
import CheckinPicker from './pages/CheckinPicker';

// components import
import TopBar from './components/TopBar';
import InstallPrompt from './components/InstallPrompt';
import BottomNav from './components/BottomNav';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';


// start the database, putting db in window so we can access it from anywhere
window.db = initDB();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <TopBar />
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/card/:member_uuid" element={<CardScanner />}></Route>
            <Route path="/scan" element={<CardScanner />}></Route>
            <Route path="/cards" element={<MyCards />}></Route>
            <Route path="/check-in" element={<CheckinPicker />}></Route>
            <Route path="/check-in/:member_uuid" element={<VirtualCard />}></Route>
        </Routes>
        <InstallPrompt />
        <BottomNav />
    </BrowserRouter>
);

serviceWorkerRegistration.register();


// check if we have just launched from the homescreen
if (window.matchMedia('(display-mode: standalone)').matches) {

}